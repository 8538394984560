import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Card as MuiCard,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  InputAdornment,
  CircularProgress,
  Chip,
  IconButton,
  Tooltip,
  Fade,
  Container
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import { collection, getDocs, query, orderBy, startAt, endAt, limit } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import AppTheme from '../../shared-theme/AppTheme';


// Adapting Card to match Dashboard container styling
const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#ffffff',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '600px',
  },
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px rgba(0, 0, 0, 0.5)'
    : '0 4px 20px rgba(0, 0, 0, 0.1)',
}));


// Match Dashboard container styling
const SignInContainer = styled(Container)(({ theme }) => ({
  maxWidth: '72rem', // matches max-w-6xl
  padding: theme.spacing(2, 4, 6), // matches px-4 py-6
  marginTop: theme.spacing(7), // matches mt-14
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4), // matches gap-4
  minHeight: '100vh',
  [theme.breakpoints.up('md')]: {
    marginTop: 0, // matches md:mt-0
  },
}));


const SearchResultsContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  width: '100%',
  maxHeight: '200px',
  overflowY: 'auto',
  marginTop: '2px',
  backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
  boxShadow: theme.shadows[3],
}));


// Match Dashboard's chip styling
const SelectedGuildChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1e293b' : '#e0f2fe',
  color: theme.palette.mode === 'dark' ? '#e2e8f0' : '#0f172a',
  fontWeight: 500,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  height: 'auto',
  '& .MuiChip-label': {
    padding: theme.spacing(0.5, 1),
  },
  '& .MuiChip-deleteIcon': {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
}));


const FeedbackContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));


// Component
export default function SignIn(props: { disableCustomTheme?: boolean }) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [selectedGuild, setSelectedGuild] = useState<string | null>(null);
  const [newGuildName, setNewGuildName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [showSelectionFeedback, setShowSelectionFeedback] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement>(null);


  const navigate = useNavigate();


  useEffect(() => {
    // Handle clicks outside the search results to close the dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    };


    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // Show selection feedback briefly when a guild is selected
  useEffect(() => {
    if (selectedGuild) {
      setShowSelectionFeedback(true);
      const timer = setTimeout(() => {
        setShowSelectionFeedback(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [selectedGuild]);


  // Debounce function to prevent excessive firestore calls
  const debounce = <F extends (...args: any[]) => any>(
    func: F,
    waitFor: number
  ) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;


    return (...args: Parameters<F>): void => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => func(...args), waitFor);
    };
  };


  const searchGuilds = async (term: string) => {
    if (!term || term.length < 2) {
      setSearchResults([]);
      setIsSearching(false);
      return;
    }
  
    setIsSearching(true);
    try {
      const guildsCollection = collection(db, 'guilds');
      const guildsSnapshot = await getDocs(guildsCollection);
      const searchTermLower = term.toLowerCase();
      
      // Process all guild documents to find matches anywhere in the name
      const results = guildsSnapshot.docs
        .map(doc => {
          // Get name from data or fallback to document ID
          const guildName = doc.data().name || doc.id;
          return guildName;
        })
        .filter(guildName => 
          guildName.toLowerCase().includes(searchTermLower)
        )
        .slice(0, 20); // Limit to 20 results
      
      setSearchResults(results);
      setShowResults(results.length > 0);
    } catch (error) {
      console.error('Error searching guilds:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };
  


  // Debounced search function to avoid too many Firestore calls
  const debouncedSearch = useRef(
    debounce((term: string) => {
      searchGuilds(term);
    }, 300)
  ).current;


  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (!selectedGuild) {
      if (value.length >= 2) {
        setIsSearching(true);
        debouncedSearch(value);
      } else {
        setSearchResults([]);
        setShowResults(false);
      }
    }
  };


  // Handle guild selection from search results
  const handleGuildSelect = (guild: string) => {
    setSelectedGuild(guild);
    setSearchTerm(guild);
    setNewGuildName('');
    setShowResults(false);
    setError('');
  };


  // Clear selected guild
  const handleClearGuild = () => {
    setSelectedGuild(null);
    setSearchTerm('');
    setError('');
  };


  const handleDiscordLogin = () => {
    setError('');
    const guildInput = newGuildName.trim();
    const guild = guildInput || selectedGuild;


    if (!guild) {
      setError('Please select a guild or enter a new guild name.');
      return;
    }


    const isNewGuild = !!guildInput;
    if (
      isNewGuild &&
      searchResults.some((existingGuild) => existingGuild.toLowerCase() === guild.toLowerCase())
    ) {
      setError(
        'Guild already exists. Please select an existing guild or choose a different name.'
      );
      return;
    }


    setIsSubmitting(true);
    const state = encodeURIComponent(JSON.stringify({ guild, isNewGuild }));
    const clientId = '1297526766604124203';
    const redirectUri = process.env.REACT_APP_DISCORD_REDIRECT_URL;
    const scope = 'identify email';


    if (!redirectUri) {
      console.error('REACT_APP_DISCORD_REDIRECT_URL is not defined');
      setError('An error occurred. Please try again later.');
      setIsSubmitting(false);
      return;
    }


    const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=code&scope=${encodeURIComponent(scope)}&state=${state}`;


    window.location.href = discordAuthUrl;
  };


  return (
    <AppTheme {...props}>
      <SignInContainer>
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{
              width: '100%',
              fontSize: 'clamp(1.75rem, 5vw, 2rem)',
              fontWeight: 600,
              color: theme => theme.palette.mode === 'dark' ? '#e2e8f0' : '#0f172a'
            }}
          >
            Sign in
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <Typography variant="body2" sx={{ color: 'rgba(225, 225, 230, 0.8)' }}>
              Search for your guild or enter a new guild name.
            </Typography>


            {/* Guild Selection Section */}
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 1 }}>
                Select Existing Guild
              </Typography>

              {/* Guild Search Input */}
              <Box ref={searchRef} sx={{ position: 'relative' }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder={selectedGuild ? "Guild selected" : "Search for a guild..."}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onFocus={() => {
                    if (searchResults.length > 0 && !selectedGuild) {
                      setShowResults(true);
                    }
                  }}
                  InputProps={{
                    style: { color: '#E1E1E6' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: 'rgba(225, 225, 230, 0.5)' }} />
                      </InputAdornment>
                    ),
                    endAdornment: selectedGuild ? (
                      <InputAdornment position="end">
                        <Tooltip title="Clear selection">
                          <IconButton
                            edge="end"
                            onClick={handleClearGuild}
                            sx={{ color: 'error.main' }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ) : isSearching ? (
                      <InputAdornment position="end">
                        <CircularProgress size={20} color="inherit" />
                      </InputAdornment>
                    ) : null,
                    readOnly: !!selectedGuild,
                  }}
                  sx={{
                    input: { color: '#E1E1E6' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: selectedGuild
                          ? 'rgba(76, 175, 80, 0.6)'
                          : 'rgba(225, 225, 230, 0.3)',
                        borderWidth: selectedGuild ? 2 : 1,
                      },
                      '&:hover fieldset': {
                        borderColor: selectedGuild
                          ? 'rgba(76, 175, 80, 0.8)'
                          : 'rgba(225, 225, 230, 0.5)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: selectedGuild ? 'rgba(76, 175, 80, 1)' : '#E1E1E6',
                      },
                    },
                  }}
                />

                {/* Selection success feedback */}
                <Fade in={showSelectionFeedback}>
                  <FeedbackContainer>
                    <CheckCircleIcon color="success" />
                  </FeedbackContainer>
                </Fade>

                {/* Search Results Dropdown */}
                {showResults && !selectedGuild && (
                  <SearchResultsContainer>
                    <List dense disablePadding>
                      {searchResults.length > 0 ? (
                        searchResults.map((guild) => (
                          <ListItem key={guild} disablePadding>
                            <ListItemButton onClick={() => handleGuildSelect(guild)}>
                              <ListItemText primary={guild} />
                            </ListItemButton>
                          </ListItem>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText primary="No guilds found" />
                        </ListItem>
                      )}
                    </List>
                  </SearchResultsContainer>
                )}
              </Box>

              {/* Selected Guild Visual Indicator */}
              {selectedGuild && (
                <SelectedGuildChip
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CheckCircleIcon fontSize="small" color="success" />
                      <Typography variant="body2">
                        Selected: <strong>{selectedGuild}</strong>
                      </Typography>
                    </Box>
                  }
                  onDelete={handleClearGuild}
                />
              )}
            </Box>


            {/* Create New Guild Section */}
            <Box sx={{ mt: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 'medium',
                  mb: 1,
                  color: selectedGuild ? 'rgba(225, 225, 230, 0.4)' : 'rgba(225, 225, 230, 0.8)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                Create New Guild
                {selectedGuild && (
                  <Typography variant="caption" color="error">
                    Clear selection to create a new guild
                  </Typography>
                )}
              </Typography>

              {/* New Guild Input */}
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter new guild name"
                value={newGuildName}
                onChange={(e) => {
                  setNewGuildName(e.target.value);
                  setSelectedGuild(null);
                  setSearchTerm('');
                  setError('');
                }}
                disabled={!!selectedGuild}
                InputProps={{
                  style: { color: '#E1E1E6' },
                  endAdornment: selectedGuild ? (
                    <InputAdornment position="end">
                      <Tooltip title="Clear guild selection to enable">
                        <IconButton
                          edge="end"
                          onClick={handleClearGuild}
                          size="small"
                          sx={{ color: 'error.main' }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ) : null
                }}
                sx={{
                  mb: 2,
                  input: { color: '#E1E1E6' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(225, 225, 230, 0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(225, 225, 230, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#E1E1E6',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      '& fieldset': {
                        borderColor: 'rgba(225, 225, 230, 0.1)',
                      }
                    },
                  },
                }}
              />
            </Box>

            {/* Error Message */}
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}

            {/* Discord Login Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleDiscordLogin}
              disabled={isSubmitting}
              fullWidth
              sx={{
                mt: 2,
                fontWeight: 'bold',
                fontSize: '1rem',
                padding: '10px 20px',
                backgroundColor: '#5865F2', // Discord's brand color
                color: '#FFFFFF',
                textTransform: 'none', // Match Dashboard button style
                '&:hover': {
                  backgroundColor: '#4852C1',
                },
              }}
            >
              {isSubmitting ? 'Processing...' : 'Continue with Discord'}
            </Button>
          </Box> {/* Add this closing tag for the Box that started on line 307 */}
        </Card>
      </SignInContainer>
    </AppTheme>
  );
}